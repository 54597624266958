import { createStore, Commit } from 'vuex'
import axios from 'axios'
import { ElMessage } from 'element-plus'

export interface UserProps {
  isLogin: boolean,
  id?: string,
  username?: string,
  userid?: string,
  mobile?: string,
  address?: string,
  token?: string,
  type?: string,
  allMoney?: string,
  chargeMoney? : string,
  allThreshold?: string,
  concurrency?: string,
  maxConcurrencyUse?: string,
  concurrencyUse?: string,
  allUse?: string,
  dayUse?: string
}
export interface FundFlowProps {
  sn?: string,
  tradeNo?: string,
  amount?: string,
  type?: string,
  addTime?: string,
  message?: string
}
export interface FundFlowAllProps {
  count?: string,
  next?: string,
  previous?: string,
  results?: FundFlowProps[]
}
export interface ExpenseReportProps {
  useVPRCount?: string,
  useTTSCount?: string,
  useASRCount?: string,
  addTime?: string,
  money?: string
}
export interface ExpenseReportAllProps {
  count?: string,
  next?: string,
  previous?: string,
  results?: ExpenseReportProps[]
}
export interface UserReportProps {
  useAsrCounts?: string,
  addTime? : string
}
export interface UserReportAllProps {
  countArr: number[],
  dateArr : string[],
  results?: UserReportProps[]
}
export interface PairTokenProps {
  refreshToken: string;
  accessToken: string;
}
export interface GlobalDataProps {
  token: PairTokenProps
  user: UserProps
  fundFlowsAll: FundFlowAllProps
  expenseReportsAll: ExpenseReportAllProps
  userReportAll: UserReportAllProps // 每日用户报表数据
  isLoading: boolean
  sideBarItem: string
}
const getAndCommit = async (url: string, mutationName: string, commit: Commit) => {
  const data = await axios.get(url).then(response => {
    // console.log('get url: ', url)
    return response
  })
  commit(mutationName, data)
}
const postAndCommit = async (url: string, mutationName: string, commit: Commit, payload: any) => {
  const { data } = await axios.post(url, payload)
  commit(mutationName, data)
  return data
}
const store = createStore<GlobalDataProps>({ // 传泛型有助于ide语法提示
  state: {
    token: {
      refreshToken: localStorage.getItem('refreshToken') || '',
      accessToken: localStorage.getItem('accessToken') || ''
    },
    isLoading: true,
    sideBarItem: '',
    user: { isLogin: false, id: localStorage.getItem('user_id') || '' },
    fundFlowsAll: {},
    expenseReportsAll: {},
    userReportAll: { dateArr: [], countArr: [] }
  },
  mutations: { // commit
    fetchCurrentUser (state, rawData) {
      state.user = { isLogin: true, ...rawData.data } // 展开rawData.data 参数值提出来给user初始化
    },
    fetchReport (state, rawData) {
      state.userReportAll.dateArr = [] // 先清空
      state.userReportAll.countArr = []
      state.userReportAll.results = rawData.data
      for (let i = 0; i < rawData.data.length; i++) {
        state.userReportAll.dateArr.push(rawData.data[i].add_time.split(' ')[0])
        state.userReportAll.countArr.push(rawData.data[i].use_asr_counts)
      }
    },
    fetchFundFlow (state, rawData) {
      state.fundFlowsAll.results = rawData.data.results.map((item: any) => { // forEach不返回东西
        item.amount = item.amount / 100
        return item
      })
      state.fundFlowsAll.count = rawData.data.count
      state.fundFlowsAll.next = rawData.data.next
      state.fundFlowsAll.previous = rawData.data.previous
    },
    fetchExpenseReport (state, rawData) {
      state.expenseReportsAll.results = rawData.data.results.map((item: any) => { // forEach不返回东西
        item.money = item.money / 100
        return item
      })
      state.expenseReportsAll.count = rawData.data.count
      state.expenseReportsAll.next = rawData.data.next
      state.expenseReportsAll.previous = rawData.data.previous
    },
    setLoading (state, status) {
      state.isLoading = status
    },
    setSideBarItem (state, Item) { // 设置侧边栏点亮的值
      state.sideBarItem = Item
    },
    fetchAccessToken (state, rawData) {
      const { access } = rawData
      state.token.accessToken = access
      axios.defaults.headers.common.Authorization = `Bearer ${access}`
      localStorage.setItem('accessToken', access)
    },
    login (state, rawData) { // 当commit字符串同于函数名时被激活
      const { refresh, access } = rawData // 解构
      state.token = { refreshToken: refresh, accessToken: access } // mutation中对数据进行修改
      axios.defaults.headers.common.Authorization = `Bearer ${access}` // 登录成功把token放到默认请求头里
      localStorage.setItem('refreshToken', refresh) // 持久化 refresh token 就行
      localStorage.setItem('user_id', rawData.user_id) // 存一下user_id
    },
    logout (state) {
      state.token = { refreshToken: '', accessToken: '' }
      state.user = { isLogin: false }
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('user_id')
      delete axios.defaults.headers.common.Authorization
    }
  },
  actions: { // dispatch
    fetchReport (context, queryParams) {
      if (queryParams) { // 大于&小于
        getAndCommit('user_report/?add_time__gte=' + queryParams[0] + '&' + 'add_time__lte=' + queryParams[1], 'fetchReport', context.commit)
      } else {
        getAndCommit('user_report/', 'fetchReport', context.commit)
      }
    },
    fetchFundFlow (context, payload) {
      getAndCommit('user_charges/?page=' + payload.currentPage, 'fetchFundFlow', context.commit)
    },
    fetchExpenseReport (context, payload) {
      getAndCommit('bills/?page=' + payload.currentPage, 'fetchExpenseReport', context.commit)
    },
    fetchCurrentUser (context, payload) {
      getAndCommit('users/' + payload.user_id + '/', 'fetchCurrentUser', context.commit)
    },
    fetchAccessToken (context, payload) {
      return postAndCommit('token_refresh/', 'fetchAccessToken', context.commit, payload)
    },
    login (context, payload) {
      return postAndCommit('login/', 'login', context.commit, payload) // 返回一个promise
    },
    mobileLogin (context, payload) {
      return postAndCommit('mobile_login/', 'login', context.commit, payload) // 返回一个promise
    },
    loginAndFetchUser (context, payload) { // 将login获取token, 然后token获取用户信息 结合(登陆完直接跳到 展示首页)
      return context.dispatch('login', payload).then(() => { // then即成功login
        return context.dispatch('fetchCurrentUser')
      })
    }
  }
})

export default store
