
import { computed, defineComponent, onMounted, onUpdated, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { GlobalDataProps } from '@/store'

export default defineComponent({
  name: 'dashboard',
  setup () {
    const store = useStore<GlobalDataProps>()
    const currentUser = computed(() => store.state.user) // 计算属性监听user动态刷新
    const Loading = ref(true) // 默认打开加载动画
    onMounted(() => { // 当这个页面就去加载数据
      store.dispatch('fetchCurrentUser', { user_id: localStorage.getItem('user_id') })
    })
    watch(currentUser, () => {
      Loading.value = false // 监听到数据变化就关闭加载动画
    })
    return {
      currentUser,
      Loading
    }
  }
})
