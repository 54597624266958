
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ASR',
  setup () {
    return {
      RequestParams: [
        {
          name: 'userid',
          isRequired: '必填',
          desc: '注册时自定义'
        },
        {
          name: 'token',
          isRequired: '必填',
          desc: '注册成功后系统生成'
        },
        {
          name: 'sid',
          isRequired: '必填',
          desc: '每次会话的唯一标识符'
        }
      ],
      ResponseParams: [
        {
          name: 'result',
          desc: '成功时返回语音识别结果，失败时返回空'
        },
        {
          name: 'errCode',
          desc: '0表示成功，其它请参考 ”错误代码及常见错误原因“'
        },
        {
          name: 'AsrRetCode',
          desc: '识别错误标识'
        }
      ],
      ErrorCodeDesc: [
        {
          name: '-1',
          desc: 'file 内容太短'
        },
        {
          name: '-2',
          desc: '语音识别失败，过程有误 或者 语音流识别申请识别引擎失败'
        },
        {
          name: '-3',
          desc: '缺少 file 参数'
        },
        {
          name: '-4',
          desc: '语音保存，生成目录错误'
        },
        {
          name: '3',
          desc: '流识别未结束'
        },
        {
          name: '2012',
          desc: '缺少 userid 参数'
        },
        {
          name: '2014',
          desc: '缺少 sid 参数'
        },
        {
          name: '2016',
          desc: '缺少 end 参数'
        },
        {
          name: '2018',
          desc: '缺少 token 参数'
        }
      ]
    }
  }
})
