import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-580c1ac6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  id: "sidebarMenu",
  class: "col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
}
const _hoisted_3 = {
  class: "position-sticky pt-5",
  style: {"margin-left":"3rem"}
}
const _hoisted_4 = { class: "nav flex-column mb-2" }
const _hoisted_5 = { class: "nav-item" }
const _hoisted_6 = { class: "nav flex-column mb-2" }
const _hoisted_7 = { class: "nav-item" }
const _hoisted_8 = { class: "nav flex-column mb-2" }
const _hoisted_9 = { class: "nav-item" }
const _hoisted_10 = { class: "nav flex-column mb-2" }
const _hoisted_11 = { class: "nav-item" }
const _hoisted_12 = { class: "nav flex-column mb-2" }
const _hoisted_13 = { class: "nav-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          _createElementVNode("li", _hoisted_5, [
            _createElementVNode("a", {
              class: _normalizeClass(["nav-link", { 'active': 'dashboard' === _ctx.sideBarItem }]),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeSidebar && _ctx.changeSidebar(...args))),
              "data-itemName": "dashboard"
            }, " 基本信息 ", 2)
          ])
        ]),
        _createElementVNode("ul", _hoisted_6, [
          _createElementVNode("li", _hoisted_7, [
            _createElementVNode("a", {
              class: _normalizeClass(["nav-link", { 'active': 'pay' === _ctx.sideBarItem }]),
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeSidebar && _ctx.changeSidebar(...args))),
              "data-itemName": "pay"
            }, " 充值 ", 2)
          ])
        ]),
        _createElementVNode("ul", _hoisted_8, [
          _createElementVNode("li", _hoisted_9, [
            _createElementVNode("a", {
              class: _normalizeClass(["nav-link", { 'active': 'fund-flow' === _ctx.sideBarItem }]),
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.changeSidebar && _ctx.changeSidebar(...args))),
              "data-itemName": "fund-flow"
            }, " 充值明细 ", 2)
          ])
        ]),
        _createElementVNode("ul", _hoisted_10, [
          _createElementVNode("li", _hoisted_11, [
            _createElementVNode("a", {
              class: _normalizeClass(["nav-link", { 'active': 'expense-report' === _ctx.sideBarItem }]),
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.changeSidebar && _ctx.changeSidebar(...args))),
              "data-itemName": "expense-report"
            }, " 账单明细 ", 2)
          ])
        ]),
        _createElementVNode("ul", _hoisted_12, [
          _createElementVNode("li", _hoisted_13, [
            _createElementVNode("a", {
              class: _normalizeClass(["nav-link", { 'active': 'report' === _ctx.sideBarItem }]),
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.changeSidebar && _ctx.changeSidebar(...args))),
              "data-itemName": "report"
            }, " 历史报表 ", 2)
          ])
        ])
      ])
    ])
  ]))
}