
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ASR',
  setup () {
    return {
      RequestParams: [
        {
          name: 'userid',
          isRequired: '必填',
          desc: '注册时自定义'
        },
        {
          name: 'token',
          isRequired: '必填',
          desc: '注册成功后系统生成'
        },
        {
          name: 'file',
          isRequired: '必填',
          desc: '音频文件'
        }
      ],
      ResponseParams: [
        {
          name: 'result',
          desc: '成功时返回语音识别结果，失败时返回空'
        },
        {
          name: 'errCode',
          desc: '0表示成功，其它表示失败。'
        }
      ]
    }
  }
})
