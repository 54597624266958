
import { defineComponent, reactive } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'
import ValidateInput, { RulesProp } from '../components/ValidateInput.vue'
import ValidateSelect from '@/components/ValidateSelect.vue'
import ValidateForm from '../components/ValidateForm.vue'
import { ElMessage } from 'element-plus'
const typeMap = (type: string) => {
  if (type === '个人') {
    return 0
  } else if (type === '公司') {
    return 1
  }
}

export default defineComponent({
  name: 'Signup',
  components: {
    ValidateInput,
    ValidateForm,
    ValidateSelect
  },
  setup () {
    const formData = reactive({
      email: '',
      username: '',
      mobile: '',
      address: '',
      type: '',
      password: '',
      repeatPassword: ''
    })
    const router = useRouter()
    const emailRules: RulesProp = [
      { type: 'required', message: '电子邮箱地址不能为空' },
      { type: 'email', message: '请输入正确的电子邮箱格式' }
    ]
    const usernameRules: RulesProp = [
      { type: 'required', message: '用户名不能为空' }
    ]
    const mobileRules: RulesProp = [
      { type: 'required', message: '手机号码不能为空' },
      { type: 'mobile', message: '请输入正确的手机格式' }
    ]
    const typeRules: RulesProp = [
      { type: 'required', message: '类别不能为空' }
    ]
    const passwordRules: RulesProp = [
      { type: 'required', message: '密码不能为空' }
    ]
    const repeatPasswordRules: RulesProp = [
      { type: 'required', message: '重复密码不能为空' },
      {
        type: 'custom',
        validator: () => {
          return formData.password === formData.repeatPassword
        },
        message: '密码不相同'
      }
    ]
    const onFormSubmit = (result: boolean) => {
      if (result) {
        const payload = {
          username: formData.username,
          mobile: formData.mobile,
          address: formData.address,
          type: typeMap(formData.type),
          email: formData.email,
          password: formData.password
        }
        axios.post('/users/', payload).then(() => {
          ElMessage({
            message: '注册成功 正在跳转登录页面',
            type: 'success'
          })
          setTimeout(() => {
            router.push('/login')
          }, 2000)
        }).catch(error => {
          console.log(error)
          if (error.response) {
            if (error.response.status === 400) { // 注册失败, 参数有误
              const { data } = error.response
              for (const item in data) {
                setTimeout(() => {
                  console.log(item, data[item][0])
                  ElMessage({
                    message: data[item][0],
                    type: 'error'
                  })
                }, 500)
              }
            }
          }
        })
      }
    }
    return {
      emailRules,
      usernameRules,
      mobileRules,
      passwordRules,
      typeRules,
      repeatPasswordRules,
      onFormSubmit,
      formData
    }
  }
})
