
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'expenseReport',
  setup () {
    const store = useStore()
    onMounted(() => { // 当这个页面就去加载数据
      store.dispatch('fetchExpenseReport', { currentPage: currentPage.value })
    })
    const expenseReportsResults = computed(() => store.state.expenseReportsAll.results) // 对应页码的数据
    const expenseReportsCount = computed(() => store.state.expenseReportsAll.count) // 当前用户的数据总数
    const currentPage = ref(1) // 当前页码
    const pageSize = ref(10) // 每页数量
    const tableLoading = ref(true)
    watch(currentPage, (newVal) => { // 监听页码值变化 请求对应页码的数据
      store.dispatch('fetchExpenseReport', { currentPage: newVal })
    })
    watch(expenseReportsResults, () => { // 监听页码的数据变化 取消加载动画
      tableLoading.value = false
    })
    return {
      tableData: expenseReportsResults,
      currentPage: currentPage,
      count: expenseReportsCount,
      tableLoading,
      pageSize
    }
  }
})
