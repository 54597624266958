import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "dropdown",
  ref: "dropdownRef"
}
const _hoisted_2 = {
  key: 0,
  class: "dropdown-menu",
  "aria-labelledby": "dropdownMenuLink",
  style: {"display":"block"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      href: "#",
      class: "btn btn-outline-dark my-2 dropdown-toggle",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleOpen && _ctx.toggleOpen(...args)), ["prevent"])),
      style: {"margin-right":"4rem"}
    }, _toDisplayString(_ctx.title), 1),
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true)
  ], 512))
}