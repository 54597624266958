
import { defineComponent, onUnmounted } from 'vue'
import mitt from 'mitt' // 事件监听器
type ValidateFunc = () => boolean
type Events = {
  'form-item-created': ValidateFunc; // 即定义函数参数 'form-item-created 为 ValidateFunc类型'
};
export const emitter = mitt<Events>()
export default defineComponent({
  emits: ['form-submit'],
  setup (props, context) {
    const submitForm = () => { // map返回Arr中各个函数的执行结果, every在遇到false时返回
      const result = validationFuncArr.map(func => func()).every(result => result)
      context.emit('form-submit', result) // 向父组件传递form-submit信号 值为true
    }
    let validationFuncArr: ValidateFunc[] = []
    const callback = (func: ValidateFunc) => {
      validationFuncArr.push(func)
    }
    emitter.on('form-item-created', callback)
    onUnmounted(() => { // 当组件被卸载时, 清除掉监听器
      emitter.off('form-item-created', callback)
      validationFuncArr = []
    })
    return {
      submitForm: submitForm
    }
  }
})
