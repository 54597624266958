
import { defineComponent, onMounted, computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'fundFlow',
  setup () {
    const store = useStore()
    onMounted(() => { // 当这个页面就去加载数据
      store.dispatch('fetchFundFlow', { currentPage: currentPage.value })
    })
    const fundFlowsResults = computed(() => store.state.fundFlowsAll.results) // 对应页码的数据
    const fundFlowsCount = computed(() => store.state.fundFlowsAll.count) // 当前用户的数据总数
    const currentPage = ref(1) // 当前页码
    const pageSize = ref(10) // 每页数量
    const tableLoading = ref(true) // 默认打开加载动画
    watch(currentPage, (newVal) => { // 监听页码值变化 请求对应页码的数据
      store.dispatch('fetchFundFlow', { currentPage: newVal })
    })
    watch(fundFlowsResults, () => {
      tableLoading.value = false // 监听到变化就关闭加载动画
    })
    return {
      tableData: fundFlowsResults,
      currentPage: currentPage,
      count: fundFlowsCount,
      tableLoading,
      pageSize
    }
  }
})
