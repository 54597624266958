
import ValidateInput, { RulesProp } from '@/components/ValidateInput.vue'
import ValidateForm from '@/components/ValidateForm.vue'
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import axios from 'axios'

export default defineComponent({
  name: 'MobileLogin',
  components: {
    ValidateInput,
    ValidateForm
  },
  setup () {
    const router = useRouter()
    const store = useStore()
    const countDown = ref(false)
    const count = ref(60)
    const TIME_COUNT = 60
    let timer:any = null
    const onCountDownClick = async () => {
      if (!timer) {
        const stat = await axios.post('/code/', { mobile: mobileValue.value }).catch(error => {
          if (error.response) {
            if (error.response.status === 400) { // 注册失败, 参数有误
              const { data } = error.response
              for (const item in data) {
                setTimeout(() => {
                  ElMessage({
                    message: data[item][0],
                    type: 'error'
                  })
                }, 1000)
              }
            }
          }
          return 'error'
        })
        if (stat === 'error') {
          return // 如果是error就不执行之后的操作
        }
        ElMessage({
          message: '验证码发送成功',
          type: 'success'
        })
        count.value = TIME_COUNT // 这里改成TIME_COUNT的值
        countDown.value = true
        timer = setInterval(() => {
          if (count.value > 0 && count.value <= TIME_COUNT) {
            count.value--
          } else {
            countDown.value = false
            clearInterval(timer)
            timer = null
          }
        }, 1000)
      }
    }
    const mobileRules: RulesProp = [
      { type: 'required', message: '手机号码不能为空' },
      { type: 'mobile', message: '请输入正确的手机号码格式' }
    ]
    const codeRules: RulesProp = [
      { type: 'required', message: '验证码不能为空' }
    ]
    const mobileValue = ref('')
    const codeValue = ref('')
    const onFormSubmit = (result: boolean) => {
      if (result) { // 如果表单参数通过验证
        const payload = {
          mobile: mobileValue.value,
          code: codeValue.value
        }
        store.dispatch('mobileLogin', payload).then(response => { // .then promise resolve 正确回调
          ElMessage({
            message: '登录成功, 即将跳转个人中心',
            type: 'success'
          })
          store.dispatch('fetchCurrentUser', { user_id: response.user_id })
          setTimeout(() => {
            store.commit('setSideBarItem', 'dashboard')
            router.push('/home')
          }, 2000)
        }).catch(error => { // 这里捕捉promise里reject出的错误(不捕捉的话chrome会报错) catch promise rejected错误回调
          if (error.response) {
            if (error.response.status === 400) { // 注册失败, 参数有误
              const { data } = error.response
              for (const item in data) {
                setTimeout(() => {
                  console.log(item, data[item][0])
                  ElMessage({
                    message: data[item][0],
                    type: 'error'
                  })
                }, 500)
              }
            }
          }
        })
      }
    }
    return {
      mobileValue,
      mobileRules,
      codeRules,
      codeValue,
      onFormSubmit,
      countDown,
      count,
      onCountDownClick
    }
  }
})
