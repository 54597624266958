
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { GlobalDataProps } from '@/store'

export default defineComponent({
  setup () {
    const store = useStore<GlobalDataProps>()
    const sideBarItem = computed(() => store.state.sideBarItem)
    const route = useRoute()
    const router = useRouter()
    onMounted(() => {
      store.commit('setSideBarItem', route.name)
    })
    const changeSidebar = (event: any) => {
      store.commit('setSideBarItem', event.target.getAttribute('data-itemName')) // 获取标签上的路由名称 对应router.js中的name
      router.push({ // 这里路由跳转 router-view渲染切换
        name: sideBarItem.value as string
      })
    }
    return {
      changeSidebar: changeSidebar,
      sideBarItem: sideBarItem
    }
  }
})
