
import { defineComponent, ref } from 'vue'

export default defineComponent({
  emits: ['payStatus'],
  props: {
    dialogVisible: Boolean
  },
  setup (props, context) {
    // console.log('收到', props.dialogVisible)
    const centerDialogVisible = ref(props.dialogVisible)
    const onSuccess = () => {
      context.emit('payStatus', true)
      centerDialogVisible.value = false
    }
    const onFail = () => {
      context.emit('payStatus', false)
      centerDialogVisible.value = false
    }
    return {
      centerDialogVisible,
      onSuccess,
      onFail
    }
  }
})
