
import { defineComponent, PropType } from 'vue'
import Dropdown from './DropDown.vue'
import DropdownItem from './DropdownItem.vue'
import { UserProps } from '../store'
import { useStore } from 'vuex'
import router from '@/router'
import { ElMessage } from 'element-plus'
export default defineComponent({
  name: 'GlobalHeader',
  components: {
    Dropdown,
    DropdownItem
  },
  props: {
    user: {
      type: Object as PropType<UserProps>,
      required: true
    }
  },
  setup () {
    const store = useStore()
    const logout = () => {
      store.commit('logout')
      router.push('/login')
      ElMessage({
        message: '登出成功',
        type: 'success'
      })
    }
    return {
      logout
    }
  }
})
