
import { defineComponent, reactive, PropType, onMounted } from 'vue'
import { emitter } from './ValidateForm.vue'
const emailReg = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
const mobileReg = /^1[3587]\d{9}$|^147\d{8}$|^176\d{8}$/
const lettersAndNumberAndLine620Reg = /^\w{6,20}$/ // 字母或数字或下划线 6-20位
const lettersAndNumberAndLine320Reg = /^\w{3,20}$/ // 字母或数字或下划线 3-20位
const integerReg = /^[1-9]\d{0,8}$/
interface RuleProp {
  type: 'required' | 'email' | 'lettersAndNumberAndLine620' | 'lettersAndNumberAndLine320' | 'mobile' | 'integer' |'custom', // custom是用户自定义规则(可以传函数)
  message: string,
  validator?: () => boolean
}
export type RulesProp = RuleProp[]
export type TagType = 'input' | 'textarea'
export default defineComponent({
  props: {
    rules: Array as PropType<RulesProp>,
    modelValue: String,
    tag: { // 增加input类别可选
      type: String as PropType<TagType>,
      default: 'input'
    }
  },
  inheritAttrs: false, // 禁止根标签继承属性
  setup (props, context) { // context结构出emit
    const inputRef = reactive({
      val: props.modelValue || '', // 可能有初始值, 没有的话就置为空
      error: false,
      message: ''
    })
    const updateValue = (e: KeyboardEvent) => {
      const targetValue = (e.target as HTMLInputElement).value
      inputRef.val = targetValue
      context.emit('update:modelValue', targetValue) // 这里抛出了update事件
    }
    onMounted(() => {
      emitter.emit('form-item-created', validateInput) // 把验证函数传给父组件form
    })
    const validateInput = () => { // 遍历规则
      if (props.rules) {
        const allPassed = props.rules.every(rule => {
          let passed = true
          inputRef.message = rule.message
          switch (rule.type) {
            case 'required':
              passed = (inputRef.val.trim() !== '')
              break
            case 'email':
              passed = emailReg.test(inputRef.val)
              break
            case 'lettersAndNumberAndLine620':
              passed = lettersAndNumberAndLine620Reg.test(inputRef.val)
              break
            case 'lettersAndNumberAndLine320':
              passed = lettersAndNumberAndLine320Reg.test(inputRef.val)
              break
            case 'integer':
              passed = integerReg.test(inputRef.val)
              break
            case 'mobile':
              passed = mobileReg.test(inputRef.val)
              break
            case 'custom':
              passed = rule.validator ? rule.validator() : true // 有传自定义验证函数就执行 没有就直接返回true
              break
            default:
              break
          }
          return passed
        })
        inputRef.error = !allPassed
        return allPassed
      }
      return true // 没有传入验证规则 那就永远是true
    }
    return {
      updateValue: updateValue,
      validateInput: validateInput,
      inputRef: inputRef
    }
  }
})
