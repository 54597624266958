import { renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "input-validate-container" }
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]
const _hoisted_4 = {
  key: 2,
  class: "invalid-feedback",
  style: {"display":"block"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "front"),
    (_ctx.tag==='input')
      ? (_openBlock(), _createElementBlock("input", _mergeProps({
          key: 0,
          class: {'is-invalid': _ctx.inputRef.error},
          value: _ctx.modelValue,
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
          onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.validateInput && _ctx.validateInput(...args)))
        }, _ctx.$attrs), null, 16, _hoisted_2))
      : (_ctx.tag==='textarea')
        ? (_openBlock(), _createElementBlock("textarea", _mergeProps({
            key: 1,
            class: {'is-invalid': _ctx.inputRef.error},
            value: _ctx.modelValue,
            onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
            onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.validateInput && _ctx.validateInput(...args)))
          }, _ctx.$attrs), null, 16, _hoisted_3))
        : _createCommentVNode("", true),
    (_ctx.inputRef.error)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.inputRef.message), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "behind")
  ]))
}