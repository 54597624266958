
import { defineComponent, computed } from 'vue'
import { useRoute } from 'vue-router'
import {
  Location,
  Document,
  Menu as IconMenu,
  Setting,
  Download
} from '@element-plus/icons'

export default defineComponent({
  components: {
    Location,
    Document,
    Setting,
    IconMenu,
    Download
  },
  setup () {
    const route = useRoute()

    const onRoutes = computed(() => {
      return route.path
    })
    return {
      onRoutes
    }
  }
})
