
import ValidateInput, { RulesProp } from '@/components/ValidateInput.vue'
import ValidateForm from '@/components/ValidateForm.vue'
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'

export default defineComponent({
  name: 'PwdLogin',
  components: {
    ValidateInput,
    ValidateForm
  },
  setup () {
    const usernameRules: RulesProp = [
      { type: 'required', message: '用户名不能为空' }
    ]
    const passwordRules: RulesProp = [
      { type: 'required', message: '密码不能为空' }
    ]
    const usernameValue = ref('')
    const passwordValue = ref('')
    const router = useRouter()
    const store = useStore()
    const onFormSubmit = (result: boolean) => {
      if (result) { // 如果表单参数通过验证
        const payload = {
          username: usernameValue.value,
          password: passwordValue.value
        }
        store.dispatch('login', payload).then(response => { // .then promise resolve 正确回调
          ElMessage({
            message: '登录成功, 即将跳转个人中心',
            type: 'success'
          })
          store.dispatch('fetchCurrentUser', { user_id: response.user_id })
          setTimeout(() => {
            store.commit('setSideBarItem', 'dashboard')
            router.push('/home')
          }, 2000)
        }).catch(e => { // 这里捕捉promise里reject出的错误(不捕捉的话chrome会报错) catch promise rejected错误回调
          console.log('login error', e)
        })
      }
    }
    return {
      usernameValue: usernameValue,
      usernameRules: usernameRules,
      passwordRules: passwordRules,
      passwordValue: passwordValue,
      onFormSubmit: onFormSubmit
    }
  }
})
