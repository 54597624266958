import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import axios, { AxiosResponse } from 'axios'
import ElementPlus, { ElMessage } from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
const app = createApp(App)
app.use(ElementPlus, { locale: zhCn }).use(router).use(store).mount('#app')

// axios.defaults.baseURL = 'https://www.localvm.com/' // 测试用地址
axios.defaults.baseURL = 'https://tc.talentedsoft.com:58110/' // 测试2地址
// axios.defaults.baseURL = 'https://tc.talentedsoft.com:58108/' // 正式url地址

axios.interceptors.request.use(config => { // 请求前拦截器
  return config
})
axios.interceptors.response.use(response => { // 请求后拦截器
  return response
  // 拦截器rejected返回的东西 在请求的then中都能获取到(除了promise.reject,那是个error,需要从catch中获取)
}, async error => { // 响应错误拦截(除了2**以外的状态码)
  console.log(error.response)
  if (error.response) {
    if (error.response.status === 401) { // 权限异常 访问token_refresh获取access token时 传的refresh token过期了
      const { code, messages, detail } = error.response.data
      if (code === 'token_not_valid') {
        if (messages && messages[0].token_type === 'access') { // 这个是Authorization头过期了
          return await retryRequest('fetchAccessToken', error)
        } else { // refresh token过期了
          store.commit('logout') // logout清一下存的东西 否则跳到login router路由守卫会出问题
          ElMessage({
            message: '登录过期，请重新登录。',
            type: 'info'
          })
          router.push('/login') // 这里好像会出问题, 跳到login时, vue router又会做一次检验然后又到axios来
        }
      }
      if (detail && detail === 'No active account found with the given credentials') {
        ElMessage({
          message: '登录失败，用户名或密码错误。',
          type: 'error'
        })
      }
    }
    if (error.response.status === 403) { // 没带Authorization头 || Authorization头过期了
      const { code, messages, detail } = error.response.data
      if (code === 'token_not_valid') { // Authorization头过期了
        if (messages.token_type === 'access') {
          return await retryRequest('fetchAccessToken', error)
        }
        if (messages && messages[0].token_type === 'access') { // 这个是Authorization头过期了
          return await retryRequest('fetchAccessToken', error)
        }
      }
      if (detail === '身份认证信息未提供。') { // 没带Authorization头
        return await retryRequest('fetchAccessToken', error)
      }
    }
    if (error.response.status === 500) { // 服务器错误
      ElMessage({
        message: '出错了，请重试。',
        type: 'error'
      })
    }
    if (error.response.status === 429) { // 当日接口请求次数过多 too many requests
      ElMessage({
        message: '请求次数过多，此接口当日访问次数已到达限额。',
        type: 'error'
      })
    }
  }
  if (error.message) { // 网络错误, 请求还没发到服务器, 没有响应码
    if (error.message === 'Network Error') {
      ElMessage({
        message: '网络错误，请稍后重试。',
        type: 'error'
      })
    }
  }
  return Promise.reject(error) // 抛出promise的reject, 这个需要在发出这个请求的外面的函数进行捕捉(catch), 否则chrome console会报错
})

// 暂时只有get post的重试
async function retryRequest (dispatchType: string, error: any) {
  let retResponse: AxiosResponse
  retResponse = await store.dispatch(dispatchType, {
    refresh: localStorage.getItem('refreshToken')
  }).then(async () => { // 拿到新的accessToken就继续之前的请求
    const { url, method, data } = error.response.config
    switch (method) {
      case 'get':
        retResponse = await axios.get(url).then(response => {
          console.log('get response', response)
          return response
        })
        break
      case 'post':
        retResponse = await axios.post(url, data).then(response => {
          console.log('post response', response)
          return response
        })
        break
    }
    return retResponse
  })
  return retResponse
}
