
import { defineComponent, computed, watch } from 'vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import GlobalHeader from '@/components/GlobalHeader.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'

export default defineComponent({
  name: 'App',
  components: {
    GlobalHeader
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const currentUser = computed(() => store.state.user) // 计算属性监听user动态刷新
    const isLogin = computed(() => store.state.user.isLogin)
    let lastTime:number, currentTime:number, timer:number
    const timeOut = 30 * 60 * 1000 // 设置超时时间： 30分钟
    watch(isLogin, (newVal) => {
      if (newVal) {
        // 设置超时重新登录
        lastTime = new Date().getTime()
        currentTime = new Date().getTime()
        /* 鼠标移动事件 */
        window.document.onmouseover = () => { // 滑动鼠标就可以
          lastTime = new Date().getTime() // 更新操作时间
        }
        /* 定时器  间隔1秒检测是否长时间未操作页面  */
        timer = window.setInterval(testTime, 1000)
      }
    })
    function testTime () {
      currentTime = new Date().getTime() // 更新当前时间
      if (currentTime - lastTime > timeOut) { // 判断是否超时
        ElMessage({
          message: '超时未操作, 请重新登录',
          type: 'warning'
        })
        window.clearInterval(timer)
        window.document.onmouseover = null // 移除监听
        store.commit('logout') // 退出登录操作
        router.push('/login') // 跳到登录页
      }
    }
    return {
      currentUser: currentUser
    }
  }
})
