import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios'
import Home from './views/Home.vue'
import Login from './views/Login.vue'
import Signup from '@/views/Signup.vue'
import DashBoard from '@/components/DashBoard.vue'
import Pay from '@/components/Pay.vue'
import FundFlow from '@/components/FundFlow.vue'
import ExpenseReport from '@/components/ExpenseReport.vue'
import Index from '@/views/Index.vue'
import ProductIntro from '@/components/specs/ProductIntro.vue'
import ProductPrice from '@/components/specs/ProductPrice.vue'
import ASR from '@/components/specs/ASR.vue'
import Specs from '@/views/Specs.vue'
import Report from '@/components/Report.vue'
import store from '@/store'
import { ElMessage } from 'element-plus'
import ASRBuy from '@/components/ASRBuy.vue'
import ASR2 from '@/components/specs/ASR2.vue'
import DocsDownload from '@/components/specs/DocsDownload.vue'
import PwdLogin from '@/components/login/PwdLogin.vue'
import MobileLogin from '@/components/login/MobileLogin.vue'
const routerHistory = createWebHistory()
const router = createRouter({
  history: routerHistory,
  routes: [
    {
      path: '/home',
      name: 'home',
      component: Home,
      redirect: { name: 'dashboard' },
      meta: { requiredLogin: true },
      children:
        [
          {
            path: 'dashboard',
            name: 'dashboard',
            component: DashBoard,
            meta: {
              title: '首页'
            }
          },
          {
            path: 'pay',
            name: 'pay',
            component: ASRBuy,
            meta: {
              title: '充值'
            }
          },
          {
            path: 'fund-flow',
            name: 'fund-flow',
            component: FundFlow,
            meta: {
              title: '充值明细'
            }
          },
          {
            path: 'expense-report',
            name: 'expense-report',
            component: ExpenseReport,
            meta: {
              title: '账单明细'
            }
          },
          {
            path: 'report',
            name: 'report',
            component: Report,
            meta: {
              title: '历史报表'
            }
          }
        ]
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      redirect: { name: 'PwdLogin' },
      // 已登录用户访问/login 重定向到首页/home
      meta: { redirectAlreadyLogin: true }, // 路由元信息(用于细粒度的 权限控制 比如某些页面需要登录 某些页面不登陆也能看)
      children:
        [
          {
            path: 'PwdLogin',
            name: 'PwdLogin',
            component: PwdLogin
          },
          {
            path: 'MobileLogin',
            name: 'MobileLogin',
            component: MobileLogin
          }
        ]
    },
    {
      path: '/signUp',
      name: 'signUp',
      component: Signup,
      meta: { redirectAlreadyLogin: true }
    },
    {
      path: '/',
      name: 'index',
      component: Index
    },
    {
      path: '/specs',
      name: 'specs',
      redirect: { name: 'product-intro' },
      component: Specs,
      children:
        [
          {
            path: 'product-intro',
            name: 'product-intro',
            component: ProductIntro
          },
          {
            path: 'product-price',
            name: 'product-price',
            component: ProductPrice
          },
          {
            path: 'asr',
            name: 'asr',
            component: ASR
          },
          {
            path: 'asr2',
            name: 'asr2',
            component: ASR2
          },
          {
            path: 'docsDownload',
            name: 'docsDownload',
            component: DocsDownload
          }
        ]
    }]
})
router.beforeEach(async (to, from, next) => { // 导航守卫(在任何导航前执行)
  const { user, token } = store.state
  const { requiredLogin, redirectAlreadyLogin } = to.meta
  // console.log(to, from)
  if (!user.isLogin) { // 用户未登录 (刷新页面或是访问网页时)
    if (token.refreshToken && user.id) {
      if (token.accessToken) {
        axios.defaults.headers.common.Authorization = `Bearer ${token.accessToken}`
      } else {
        await store.dispatch('fetchAccessToken', { refresh: token.refreshToken })
      }
      await store.dispatch('fetchCurrentUser', { user_id: user.id }).then(() => { // await一下 等isLogin=true
        if (redirectAlreadyLogin) {
          next(from.path) // 已经登录了不能去这里
        }
        next() // 刚打开页面时去哪就去哪
      }).catch(e => {
        console.error(e)
        // store.commit('logout')
        // next('login')
      })
    } else {
      if (requiredLogin) {
        // console.log('未登录 要去的地方需要登录', user.isLogin)
        ElMessage({
          message: '请先登录',
          type: 'warning'
        })
        next('login')
      } else {
        next()
      }
    }
  } else {
    if (redirectAlreadyLogin) {
      console.log('已登录 要去的地方要未登录才行', user.isLogin)
      next('/')
    } else {
      next()
    }
  }
// if (requiredLogin && !store.state.token.refreshToken) {
//   next({ name: 'login' }) // 那么跳转/login
// } else if (redirectAlreadyLogin && store.state.user.isLogin) {
//   next('/')
// } else {
//   next() // 否则继续
// }
})
export default router
