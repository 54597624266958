
import { ref, onMounted } from 'vue'
import QRCode from 'qrcode'

export default {
  emits: ['close'],
  name: 'scan-pay-code',
  props: ['qrPath', 'orderSn', 'payType'],
  setup (props:any, context:any) {
    onMounted(() => {
      // console.log(props.orderSn, props.payType)
      if (props.payType === 'wxpay') {
        // 轮询支付结果就放在这里吧
        QRCode.toCanvas(props.qrPath, { errorCorrectionLevel: 'H', width: 200 }, function (err, canvas) {
          if (err) throw err
          var container = document.getElementById('qrcode')
          if (container) {
            // console.log('qrcode?', container)
            container.appendChild(canvas)
          }
        })
      }
    })
    const close = () => {
      context.emit('close')
    }
    return {
      close: close
    }
  }
}
