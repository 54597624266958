
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  name: 'Login',
  setup () {
    const activeName = ref('PwdLogin')
    const router = useRouter()
    const route = useRoute()
    onMounted(() => {
      activeName.value = route.name as string
    })
    const handleClick = (tab: any, event: Event) => {
      router.push('/login/' + tab.paneName) // 跳转对应的页面
    }
    return {
      activeName,
      handleClick
    }
  }
})
