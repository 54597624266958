
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProductPrice',
  setup () {
    const headCellClass = () => { // 表头合并自己加了底色，这里去掉
      return 'background:#ffffff;'
    }
    const objectSpanMethod = ({ row, column, rowIndex, columnIndex }:any) => {
      if (columnIndex === 0) {
        if (rowIndex % 6 === 0) {
          return {
            rowspan: 6,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    }
    return {
      headCellClass: headCellClass,
      objectSpanMethod: objectSpanMethod,
      tableData: [
        {
          name: '一句话识别',
          desc: '按照调用次数计费',
          more: '以预付费的方式进行收费'
        }
      ],
      quotationData: [
        {
          type: '一句话识别',
          size: '100 千次',
          price: '200.00',
          unitPrice: '2.00元 /千次'
        },
        {
          type: '一句话识别',
          size: '1,000 千次',
          price: '1,200.00',
          unitPrice: '1.20元 /千次'
        },
        {
          type: '一句话识别',
          size: '10,000 千次',
          price: '10,000.00',
          unitPrice: '1.00元 /千次'
        },
        {
          type: '一句话识别',
          size: '100,000 千次',
          price: '90,000.00',
          unitPrice: '0.90元 /千次'
        },
        {
          type: '一句话识别',
          size: '200,000 千次',
          price: '150,000.00',
          unitPrice: '0.75元 /千次'
        },
        {
          type: '一句话识别',
          size: '300,000 千次',
          price: '180,000.00',
          unitPrice: '0.60元 /千次'
        }
      ]
    }
  }
})
