
import { defineComponent, ref } from 'vue'
import ScanPayCode from '@/components/ScanPayCode.vue'
import Modal from '@/components/Modal.vue'
import axios from 'axios'
import router from '@/router'
import store from '@/store'
import { ElMessage } from 'element-plus'

export default defineComponent({
  name: 'pay',
  components: {
    ScanPayCode,
    Modal
  },
  setup () {
    const qrPath = ref('') // 支付的二维码地址
    const showPay = ref(false) // 是否显示扫码弹窗
    const showModal = ref(false) // 是否显示询问弹框
    const orderSn = ref('') // 订单号
    const payType = ref('wxpay') // 支付类别
    const packageValue = ref(200) // 套餐选项 给初始值就是默认亮的那个选项
    let queryPayResultTimer:any = null // 定时器
    // 监听关闭二维码弹框
    const closePayModal = () => {
      showPay.value = false // 关闭二维码弹窗
      showModal.value = true // 打开询问弹窗
      if (queryPayResultTimer !== null) {
        clearInterval(queryPayResultTimer)
        // console.log('查询定时器清除')
      }
    }
    // 监听弹窗支付状态
    const onPayStatus = (status: boolean) => {
      if (status) {
        // 支付成功跳转收支页
        router.push('fund-flow')
        store.commit('setSideBarItem', 'fund-flow')
      } else {
        showModal.value = false // 支付失败关闭询问弹窗
      }
    }
    // 监听支付类别设置不同查询定时器
    const onSetQueryPayResultTimer = () => {
      if (payType.value === 'alipay') {
        queryPayResultTimer = setInterval(() => { // 这个等微信弄好了可以拆出来作为函数
          axios.post('alipay/query/', { order_sn: orderSn.value }).then((response:any) => {
            if (response.data.pay_result === 'success') {
              console.log('支付成功查询:', response.data)
              closePayModal()
            }
          }).catch(e => {
            console.log('支付失败:', e)
          })
        }, 1000)
        // console.log('查询定时器设置完成, 每秒查一次')
      } else { // wxpay
        queryPayResultTimer = setInterval(() => { // 这个等微信弄好了可以拆出来作为函数
          axios.post('wxpay/query/', { order_sn: orderSn.value }).then((response:any) => {
            if (response.data.pay_result === 'success') {
              console.log('支付成功查询:', response.data)
              closePayModal()
            }
          }).catch(e => {
            console.log('支付失败:', e)
          })
        }, 1000)
        // console.log('查询定时器设置完成, 每秒查一次')
      }
    }

    const onChargeClick = () => { // 充值表单提交通过
      if (payType.value && packageValue.value) {
        const payload = { amount: packageValue.value } // 这里要改 根据值 选择amount的值
        // const payload = { amount: 0.01 } // 测试金额
        if (payType.value === 'alipay') {
          // 请求alipay的支付二维码(这是要填入iframe的链接)
          axios.post('alipay/QR/', payload).then((response:any) => {
            qrPath.value = response.data.pay_url
            orderSn.value = response.data.order_sn
            payType.value = 'alipay'
            showPay.value = true
            // console.log('二维码返回响应:', response.data)
            onSetQueryPayResultTimer() // 设置一下查询定时器
          }).catch(() => {
            showPay.value = false
            ElMessage({
              message: '二维码生成失败，请点击重试.',
              type: 'error'
            })
          })
        } else if (payType.value === 'wxpay') {
          // 请求wxpay的支付二维码(这是生成二维码的链接)
          axios.post('wxpay/QR/', payload).then((response:any) => {
            qrPath.value = response.data.pay_url
            orderSn.value = response.data.order_sn
            payType.value = 'wxpay'
            showPay.value = true
            // console.log('二维码返回响应:', response.data)
            onSetQueryPayResultTimer() // 设置一下查询定时器
          }).catch(() => {
            showPay.value = false
            ElMessage({
              message: '二维码生成失败，请点击重试.',
              type: 'error'
            })
          })
        }
      }
    }
    const packageDatas = [
      {
        title: '套餐一',
        amount: 200,
        desc: '100千次'
      },
      {
        title: '套餐二',
        amount: 1200,
        desc: '1000千次'
      },
      {
        title: '套餐三',
        amount: 10000,
        desc: '10000千次'
      },
      {
        title: '套餐四',
        amount: 90000,
        desc: '100000千次'
      },
      {
        title: '套餐五',
        amount: 150000,
        desc: '200000千次'
      },
      {
        title: '套餐六',
        amount: 180000,
        desc: '300000千次'
      }
    ]
    return {
      qrPath,
      orderSn,
      payType,
      showPay,
      showModal,
      packageValue,
      packageDatas,
      closePayModal,
      onChargeClick,
      onPayStatus
    }
  }
})
