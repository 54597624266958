
import { computed, defineComponent, onMounted, reactive, toRefs, watch, ref, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import * as echarts from 'echarts'
import dayjs from 'dayjs'
import { EChartsType } from 'echarts'

export default defineComponent({
  setup () {
    const state = reactive({
      value: ''
    })
    const store = useStore()
    const echartsLoading = ref(true) // 默认有加载动画
    type EChartsOption = echarts.EChartsOption;
    const option: EChartsOption = {
      xAxis: {
        type: 'category',
        data: [],
        name: '日期',
        nameTextStyle: {
          fontStyle: 'normal',
          fontSize: 15
        },
        axisLabel: {
          interval: 0 // 全部加载 不要隐藏
        }
      },
      yAxis: {
        type: 'value',
        name: '使用量(次)',
        nameTextStyle: {
          fontStyle: 'normal',
          fontSize: 15
        }
      },
      series: [
        {
          data: [],
          type: 'line',
          label: {
            show: true // 圆点显示数值
          }
        }
      ]
    }
    let myChart: EChartsType
    onMounted(() => { // 当这个页面就去加载数据
      store.dispatch('fetchReport', [startTime.value, endTime.value])
      setTimeout(() => { // 延时加载echarts初始化函数
        console.log('count arr: ', UserReportCountArr.value) // 拿得到 但是echarts好像显示太快？
        const chartDom = document.getElementById('charts')
        myChart = echarts.init(chartDom as HTMLElement)
        if (option.xAxis && option.yAxis) {
          (option.xAxis as any).data = UserReportDateArr.value; (option.series as any)[0].data = UserReportCountArr.value
        }
        myChart.setOption(option)
      }, 200)
    })
    onBeforeUnmount(() => {
      if (!myChart) {
        return
      }
      myChart.dispose() // 释放资源
    })
    const UserReportCountArr = computed(() => store.state.userReportAll.countArr)
    const UserReportDateArr = computed(() => store.state.userReportAll.dateArr)
    watch(UserReportCountArr, () => { // 监听store的数据变化 数据改变了就可以重新刷新echarts
      if (myChart) { // 这里好像会undefined 没怎么搞懂 总之if先判断一下
        if (option.xAxis && option.yAxis) {
          (option.xAxis as any).data = UserReportDateArr.value; (option.series as any)[0].data = UserReportCountArr.value
        }
        myChart.setOption(option)
      }
      echartsLoading.value = false // 关闭加载动画
    })
    // const onDateChange = (v:any) => { // 时间变化了就发请求重新获取数据
    //   echartsLoading.value = true // 开启加载动画
    //   startTime.value = dayjs(v[0]).format('YYYY-MM-DD') // 格式化Date对象为string
    //   endTime.value = dayjs(v[1]).format('YYYY-MM-DD')
    //   store.dispatch('fetchReport', [startTime.value, endTime.value])
    // }
    const endTime = ref(dayjs(new Date()).format('YYYY-MM-DD')) // 当前时间
    const startTime = ref(dayjs(new Date()).subtract(7, 'day').format('YYYY-MM-DD')) // 当前时间前移15天

    const onStartDateChange = (v:any) => {
      echartsLoading.value = true // 开启加载动画
      startTime.value = dayjs(v).format('YYYY-MM-DD') // 格式化Date对象为string
      store.dispatch('fetchReport', [startTime.value, endTime.value])
    }
    const onEndDateChange = (v:any) => {
      echartsLoading.value = true // 开启加载动画
      endTime.value = dayjs(v).format('YYYY-MM-DD') // 格式化Date对象为string
      store.dispatch('fetchReport', [startTime.value, endTime.value])
    }
    return {
      ...toRefs(state),
      startTime,
      endTime,
      onStartDateChange,
      onEndDateChange,
      echartsLoading
      // onDateChange
    }
  }
})
