import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, mergeProps as _mergeProps, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "select-validate-container" }
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]
const _hoisted_4 = {
  key: 0,
  class: "invalid-feedback",
  style: {"display":"block"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "front"),
    _createElementVNode("select", _mergeProps({
      class: ["form-select", {'is-invalid': _ctx.inputRef.error}],
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
      onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.validateInput && _ctx.validateInput(...args)))
    }, _ctx.$attrs), [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content, (item, index) => {
        return (_openBlock(), _createElementBlock("option", {
          key: index,
          value: item
        }, _toDisplayString(item), 9, _hoisted_3))
      }), 128))
    ], 16, _hoisted_2),
    (_ctx.inputRef.error)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.inputRef.message), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "behind")
  ]))
}